<template>
  <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit, invalid }"
  >
    <!-- Status Info: Input Fields -->
    <b-form
        @submit.prevent="handleSubmit(submitData)"
        @reset.prevent="resetForm"
    >
      <b-row>
        <!-- Field: label -->
        <b-col cols="12" md="4">
          <validation-provider
              #default="validationContext"
              name="label"
              rules="required"
          >
            <b-form-group label="Libellé" label-for="label">
              <b-form-input
                  id="label"
                  v-model="measure.name"
                  type="text"
              />
              <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <!-- Field: Description -->
        <b-col cols="12" md="4">
          <!-- details -->
          <validation-provider #default="validationContext" name="description">
            <b-form-group label="Description" label-for="description">
              <b-form-textarea
                  v-model="measure.description"
                  id="description"
              />
              <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Action Buttons -->
      <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
          :disabled="isLoading || invalid"
      >
        <b-spinner v-if="isLoading" small></b-spinner>
        {{ measure.uid ? "Modifier" : "Sauvegarder" }}
      </b-button>
      <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Annuler
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BFormTextarea,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { computed, ref, watch } from "@vue/composition-api";
import { heightFade } from "@core/directives/animations";
import formValidation from "@core/comp-functions/forms/form-validation";
import store from "@/store";
import { required } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";

export default {
  name: "HandleStoreTabGeneral",
  components: {
    BSpinner,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    measureData: {
      type: Object,
      required: true,
    },
  },
  emit: ['data-handled'],
  directives: {
    "height-fade": heightFade,
  },
  setup(props, { emit }) {
    const toast = useToast();

    const isLoading = ref(false);
    const measure = ref(JSON.parse(JSON.stringify(props.measureData)));

    const resetMeasureLocal = () => {
      measure.value = ref(JSON.parse(JSON.stringify(props.measureData)));
    };

    const showToast = (messages, icon, variant) => {
      toast({
        component: ToastificationContent,
        props: {
          title: messages,
          icon: icon,
          variant: variant,
        },
      });
    };

    const getError = computed(() => {
      return store.getters["measure/errors"];
    });

    const resetError = () => store.commit("measure/SET_ERRORS", null);

    const { refFormObserver, getValidationState, resetForm, clearForm } =
        formValidation(resetMeasureLocal);

    const handleSuccess = (message) => {
      emit("data-handled");
      showToast(message, "CheckIcon", "success");
    };

    const handleErrors = (errors) => {
      for (const [field, message] of Object.entries(errors)) {
        refFormObserver.value.setErrors({ [field]: message[0] });
      }
    };

    watch(getError, (val) => {
      if (val) {
        showToast(val.message, "AlertTriangleIcon", "danger");
        handleErrors(val.error);
      }
    });

    const submitData = async () => {
      isLoading.value = true;
      resetError();

      try {
        if (measure.uid) {
          const { message } = await store.dispatch(
              "measure/updateMeasure",
              { data: measure.value }
          );
          handleSuccess(message);
        } else {
          const { message } = await store.dispatch("measure/addMeasure", {
            data: measure.value,
          });
          handleSuccess(message);
        }
        clearForm();
      } catch (e) {
        //
      } finally {
        resetError();
        isLoading.value = false;
      }
    };

    return {
      required,
      isLoading,
      measure,
      submitData,
      refFormObserver,

      resetForm,
      getValidationState,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
