<template>
  <component :is="measureData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="measureData === undefined">
      <h4 class="alert-heading">Erreur de récupération des données de mesures</h4>
      <div class="alert-body">
        Pas de Marque trouvé sur cet identifiant. Vérifiez
        <b-link class="alert-link" :to="{ name: 'apps-measures-list' }">
          la liste des mesures
        </b-link>
        pour d'autres mesures.
      </div>
    </b-alert>

    <b-tabs v-if="measureData" pills>
      <!-- Tab: Général -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Création Mesure</span>
        </template>
        <handle-measure-tab-general
          class="mt-2 pt-75"
          :measure-data.sync="measureData"
          @data-handled="$router.push({ name: 'apps-measures-list' })"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
/* eslint-disable */
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import HandleMeasureTabGeneral from "./HandleMeasureTabGeneral.vue";
import useMeasuresList from "@/views/apps/measure/measures-list/useMeasuresList";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "HandleMeasure",
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    HandleMeasureTabGeneral,
  },
  setup() {
    const blankMeasureData = {
      name: "",
      description: "",
    };

    const measureData = ref(JSON.parse(JSON.stringify(blankMeasureData)));

    const fetchMeasure = () => {
      store
        .dispatch("measure/fetchMeasure", { id: router.currentRoute.params.id })
        .then((measure) => {
          measureData.value = measure;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            measureData.value = undefined;
          }
        });
    };

    onMounted(() => {
      if (router.currentRoute.params.id) {
        fetchMeasure();
      }
    });

    return {
      measureData,
    };
  },
};
</script>
